import React, { useState } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import SomaForm from '../../../components/SomaStandard/SomaForm';
import Dropdown from '../../../components/SomaStandard/FormComponents/Dropdown';
import { toast } from 'react-toastify';

import useAffiliate from '../../../Hooks/AffiliateContext'
import api from '../../../helpers/apiHelper';
import MaisAtacado from '../../../Services/MaisAtacado/MaisAtacado';

const FormVendedor = (props) => {
  const [state, setState] = useState({ state: '', operation: 'create', ...props.state });
  const [lockedFields, setLockedFields] = useState({ ...props.lockedFields });
  const { acceptedNewContract } = useAffiliate()

  const parseCep = (cep) => cep.match(/\d/g)?.slice(0, 8).map((c, i, l) => { if (i === 2) return '.' + c; if (i === 5) return '-' + c; return c; }).join('');
  const parseCPF = (cpf) => cpf.match(/\d/g)?.slice(0, 11).map((c, i) => { if (i === 3 || i === 6) return '.' + c; if (i === 9) return '-' + c; return c; }).join('');
  const validateCPF = (e) => {
    if (!e) return false;
    let dig = e.match(/\d/g).map(d => parseInt(d));
    let dv = [
      (dig?.slice(0, 9).map((n, i) => n * (10 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10,
      (dig?.slice(0, 10).map((n, i) => n * (11 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10
    ];
    let rep = !dig?.slice(0, 9).map((e, i, l) => e === l[0]).reduce((_, e) => _ && e);

    return dig[9] === dv[0] && dig[10] === dv[1] && rep
  }


  let CepLookupEvent = 0;
  const cepLookup = async (cep) => {
    const ans = await fetch(`http://viacep.com.br/ws/${cep}/json/`).then(r => r.json())

    setState({ ...state, city: ans.localidade, state: ans.uf, postalCode: parseCep(cep) })
  }

  let cpfLookupEvent = 0
  const cpfLookup = async (cpf) => {
    const ans = await api(() => MaisAtacado.get(`/v1/Afiliados/cpf/${cpf}`))
    if (ans.data.affiliate_name) {
      setState({
        name: ans.data.affiliate_name,
        email: ans.data.info.email,
        document: ans.data.info.CPF,
        phone: ans.data.info.phone,
        cellphone: ans.data.phone_2,
        postalCode: ans.data.info.CEP,
        city: ans.data.info.city,
        state: ans.data.info.state,
        operation: 'reassign'
      })

      setLockedFields({
        name: true,
        email: true,
        document: true,
        phone: true,
        cellPhone: true,
        postalCode: true,
        city: true,
        state: true
      })
    }
  }

  const Opts = {
    state: [state, setState],
    setResults: async (result) => {
      result.father_id = props.pai.id
      result.acceptedNewContract = acceptedNewContract
      const response = await authFetch('/v1/afiliados/Vendedor', 'POST', result)
      const afiliado = await response.json()
      if (afiliado.success) {
        toast.success("Cadastro Efetuado com sucesso!", { theme: "colored" })
      }
      else {
        toast.error(afiliado.message, { theme: "colored" })
      }
      props.reload();
    },
    disable: lockedFields,
    columns: [
      {
        propText: 'CPF*',
        propName: 'document',
        parse: (cpf) => {
          if (cpf.match(/\d/g)?.length === 11) {
            clearTimeout(cpfLookupEvent)
            cpfLookupEvent = setTimeout(() => cpfLookup(cpf.match(/\d/g).join('')), 300)
          }
          return parseCPF(cpf)
        },
        validate: [
          { test: (e) => e, message: 'Obrigatorio' },
          { test: validateCPF, message: 'CPF invalido' }
        ]
      },
      {
        propText: 'Nome Completo*',
        propName: 'name',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split(' ').length >= 2, message: 'Nome e Sobrenome' }]
      },
      {
        propText: 'Email*',
        propName: 'email',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split('@').length >= 2, message: 'Email Invalido' }]
      },
      {
        propText: 'Telefone',
        propName: 'phone',
        parse: (v) => v.match(/\d/g)?.slice(0, 10).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 6) return '-' + c;
          return c;
        }).join('')
      },
      {
        propText: 'Celular',
        propName: 'cellPhone',
        parse: (v) => v.match(/\d/g)?.slice(0, 11).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 7) return '-' + c;
          return c;
        }).join('')
      },
      {
        propText: 'CEP',
        propName: 'postalCode',
        parse: (v) => {
          if (v.match(/\d/g)?.length === 8) {
            clearTimeout(CepLookupEvent)
            CepLookupEvent = setTimeout(() => cepLookup(v.match(/\d/g).join('')), 300)
          }
          return parseCep(v)
        }
      },
      {
        propText: 'Cidade*',
        propName: 'city',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Estado*',
        propName: 'state',
        component: Dropdown,
        componentProps: {
          options: [
            { text: 'Acre', value: 'AC' },
            { text: 'Alagoas', value: 'AL' },
            { text: 'Amapá', value: 'AP' },
            { text: 'Amazonas', value: 'AM' },
            { text: 'Bahia', value: 'BA' },
            { text: 'Ceará', value: 'CE' },
            { text: 'Distrito Federal', value: 'DF' },
            { text: 'Espírito Santo', value: 'ES' },
            { text: 'Goiás', value: 'GO' },
            { text: 'Maranhão', value: 'MA' },
            { text: 'Mato Grosso', value: 'MT' },
            { text: 'Mato Grosso do Sul', value: 'MS' },
            { text: 'Minas Gerais', value: 'MG' },
            { text: 'Pará', value: 'PA' },
            { text: 'Paraíba', value: 'PB' },
            { text: 'Paraná', value: 'PR' },
            { text: 'Pernambuco', value: 'PE' },
            { text: 'Piauí', value: 'PI' },
            { text: 'Rio de Janeiro', value: 'RJ' },
            { text: 'Rio Grande do Norte', value: 'RN' },
            { text: 'Rio Grande do Sul', value: 'RS' },
            { text: 'Rondônia', value: 'RO' },
            { text: 'Roraima', value: 'RR' },
            { text: 'Santa Catarina', value: 'SC' },
            { text: 'São Paulo', value: 'SP' },
            { text: 'Sergipe', value: 'SE' },
            { text: 'Tocantins', value: 'TO' }
          ]
        },
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      /*{
        propText: 'Banco',
        propName: 'banco',
        component: Dropdown,
        componentProps: {
          options: [
            { value: '001', text: 'Banco do Brasil S.A.' },
            { value: '033', text: 'Banco Santander (Brasil) S.A' },
            { value: '104', text: 'Caixa Econômica Federal' },
            { value: '237', text: 'Banco Bradesco S.A' },
            { value: '341', text: 'Banco Itaú S.A' },
            { value: '356', text: 'Banco Real S.A' },
            { value: '389', text: 'Banco Mercantil do Brasil S.A' },
            { value: '399', text: 'HSBC Bank Brasil S.A.' },
            { value: '422', text: 'Banco Safra S.A' },
            { value: '453', text: 'Banco Rural S.A' },
            { value: '633', text: 'Banco Rendimento S.A' },
            { value: '652', text: 'Itaú Unibanco Holding S.A' },
            { value: '745', text: 'Banco Citibank S.A.' },
            { value: '212', text: 'Banco Original S.A.' }
          ]
        }
      },*/
    ]
  }

  return (
    <>
      <SomaForm {...Opts} />
    </>
  )
}

export default FormVendedor;
